export const GraphqlClientNames = [
    'default', // default must be the first here to be the last in the link-chain in client.ts
    'accounting',
    'auth',
    'balena',
    'booking',
    'analytics',
    'creditCards',
    'clientApp',
    'functions',
    'gainSharing',
    'giftCards',
    'images',
    'leads',
    'promoCodes',
    'reporting',
    'scheduling',
    'surgeryCalendar',
    'textMessaging',
    'vetcove',
    'vetspire',
    'website',
] as const;

export type GraphqlClientName = (typeof GraphqlClientNames)[number];

type GraphqlClientNamesType = Readonly<{
    [key in GraphqlClientName]: GraphqlClientName;
}>;

export const GRAPHQL_CLIENT_NAMES = GraphqlClientNames.reduce(
    (acc, clientName) => ({
        ...acc,
        [clientName]: clientName,
    }),
    {},
) as GraphqlClientNamesType;

// Set to the location of HQ as of 04/16/2022
// 111 W 19th St
export const HQ_LATITUDE = 40.741;
export const HQ_LONGITUDE = -73.995;
